@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply scroll-smooth;
  }
}

@layer components {
  .andie-dark-button {
    @apply cursor-pointer h-9 px-4 w-full text-white flex items-center justify-center text-sm font-productsans-medium rounded-sm focus:ring focus:ring-indigo-300 bg-blue-600 hover:bg-blue-700 transition ease-in-out duration-200;
  }

  .andie-outline-button {
    @apply cursor-pointer inline-flex items-center justify-center h-9 px-4 text-sm font-productsans-medium w-full rounded-sm text-gray-600 transition-all duration-200 border-2 border-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 focus:bg-gray-900 hover:text-blue-600 focus:text-white hover:border-blue-600 focus:border-gray-900;
  }

  .andie-page-text {
    @apply text-sm tracking-wide text-gray-700 ;
  }

  .andie-padding-horizontal {
    @apply md:px-5 xl:px-12;
  }

  .andie-margin-horizontal {
    @apply md:mx-5 xl:mx-12;
  }

  .andie-form-title {
    @apply font-productsans-bold text-2xl md:text-3xl mb-8 leading-none;
  }

  .andie-form-header {
    @apply font-productsans-medium text-base mt-2 md:text-lg leading-none;
  }

  .andie-page-title {
    @apply font-productsans-bold text-5xl md:text-6xl xl:text-[85px] leading-none;
  }

  .andie-page-description {
    @apply text-base font-roboto-regular;
  }

  .andie-section-title {
    @apply font-productsans-bold text-3xl lg:text-5xl leading-none;
  }

  .andie-blog-title {
    @apply font-productsans-bold text-xl;
  }

  .andie-section-subheader {
    @apply text-lg font-productsans-bold leading-normal;
  }

  .andie-section-description {
    @apply text-base font-roboto-medium md:max-w-lg text-gray-600 leading-tight;
  }

  .andie-section-heading {
    @apply text-base font-productsans-bold;
  }

  .andie-image-heading {
    @apply text-lg font-productsans-regular;
  }

  .andie-paragraph {
    @apply text-gray-500 text-sm font-roboto-regular tracking-normal;
  }

  .andie-narrow-section {
    @apply md:px-8 lg:px-28;
  }

  .hero-header {
    @apply text-4xl font-bold leading-tight text-gray-900 sm:text-5xl sm:leading-tight lg:text-6xl lg:leading-tight ;
  }

  .footer-header {
    @apply text-[13px] font-productsans-black tracking-widest text-gray-900 uppercase;
  }

  .footer-item {
    @apply inline-flex text-sm font-roboto-regular tracking-wide text-gray-700 transition-all duration-300 transform  hover:text-gray-600 hover:translate-x-1;
  }
  
  .andie-padding-vertical { 
    @apply py-5;
  }

  .andie-padding { 
    @apply py-5 px-5;
  }

  .andie-padding-top { 
    @apply pt-5;
  }

  .andie-padding-bottom { 
    @apply pb-5;
  }

  .andie-padding-left { 
    @apply pl-5;
  }

  .andie-padding-right { 
    @apply pr-5;
  }

  .andie-button-text {
    @apply font-productsans-medium text-xs text-indigo-800;
  }

  .andie-text-heading {
    @apply font-productsans-medium text-lg;
  }

  .andie-sectionheader {
    @apply text-5xl font-productsans-medium text-gray-900;
  }

  .ticker-tag {
    @apply px-4 pt-1 pb-2 items-center border rounded-full cursor-pointer font-bold text-black text-xs hover:shadow-lg hover:bg-gray-100 active:scale-95 transition transform duration-100 ease-out;
  }

  .search-container {
    @apply flex w-full items-center hover:shadow-lg focus-within:shadow-lg max-w-md border border-gray-100 px-5 sm:max-w-xl h-11 lg:max-w-2xl rounded-full;
  }

  .btn-ticker {
    @apply bg-tickergray p-3 rounded-md ring-gray-200 text-sm text-gray-800 hover:ring-1 focus:outline-none hover:shadow-md;
  }

  .btn-next {
    @apply block w-full px-4 py-2 mt-6 font-semibold text-white transition duration-500 ease-in-out transform rounded-sm bg-gradient-to-r from-black hover:from-black to-black focus:outline-none focus:ring-2 ring-offset-current ring-offset-2 hover:to-black;
  }

  .btn-back {
    @apply block w-full px-4 py-2 mt-6 font-semibold text-black transition duration-500 ease-in-out transform rounded-lg bg-gradient-to-r from-gray-100 hover:bg-gray-300 to-gray-300 focus:outline-none focus:ring-2 ring-offset-current ring-offset-2 hover:to-gray-200;
  }

  .btn-google {
    @apply inline-flex w-full px-4 py-2 font-semibold text-black transition duration-500 ease-in-out transform bg-white border rounded-lg border-gray-200 hover:bg-black hover:text-white focus:outline-none focus:ring-2 ring-offset-current ring-offset-2;
  }

  .modal-close-button {
    @apply inline-flex justify-center p-1 text-sm font-medium text-gray-700 bg-red-100 border border-transparent rounded-full hover:bg-red-200 items-end;
    /* focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 focus-visible:rounded-full */
  }

  .modal-container {
    @apply inline-block w-full max-w-lg h-3/4 p-6 mt-32 mb-5 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl;
  }

  .react-datepicker__input-container input {
    @apply block w-full px-4 py-2 mt-2 text-sm text-gray-700 bg-gray-50 border-gray-100 md:text-sm rounded;
    /* form-input */
  }

  .react-datepicker__tab-loop {
    @apply absolute;
  }

  .react-datepicker-popper {
    @apply absolute top-1 z-10 left-0 w-72 text-sm transform-none bg-white shadow px-3 py-2 !important;
  }

  .react-datepicker__month-container {
    @apply flex flex-col;
  }

  .react-datepicker__month {
    @apply flex flex-col;
  }

  .react-datepicker__current-month {
    @apply ml-2.5 text-lg font-semibold text-gray-800;
  }

  .react-datepicker__week {
    @apply flex justify-around;
  }

  .react-datepicker__day-names {
    @apply flex justify-around text-gray-800 font-medium text-center text-xs;
  }

  .react-datepicker__day-name {
    @apply w-8 h-8 flex items-center justify-center py-1 rounded-full;
  }

  .react-datepicker__navigation {
    @apply absolute top-2;
  }

  .react-datepicker__navigation--previous {
    @apply right-12 w-8 h-8 rounded transition flex items-center justify-center hover:bg-gray-200;
  }

  .react-datepicker__navigation--next {
    @apply right-4 w-8 h-8 rounded transition flex items-center justify-center hover:bg-gray-200;
  }

  .react-datepicker__day {
    @apply mb-1 w-8 h-8 flex items-center justify-center py-1 text-sm leading-loose transition text-gray-700 rounded;
  }

  .react-datepicker__day--disabled {
    @apply cursor-not-allowed opacity-50 hover:bg-transparent;
  }

  .react-datepicker__day--outside-month {
    @apply text-gray-400;
  }

  .react-datepicker__day--in-range {
    @apply bg-gray-200;
  }

  .react-datepicker__day--in-selecting-range {
    @apply bg-blue-200;
  }

  .react-datepicker__day--selecting-range-start {
    @apply bg-white border-2 border-blue-500;
  }

  .react-datepicker__day--selecting-range-end {
    @apply bg-white border-2 border-blue-500;
  }

  .react-datepicker__day--selected {
    @apply bg-blue-500 text-white;
  }

  .react-datepicker__day--range-start {
    @apply bg-blue-500 text-white hover:text-gray-700 hover:bg-white;
  }

  .react-datepicker__day--range-end {
    @apply bg-blue-500 text-white hover:text-gray-700 hover:bg-white;
  }

}