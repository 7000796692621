@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Product Sans Thin";
  src: url('./assets/fonts/ProductSans-Thin.ttf');
}

@font-face {
  font-family: "Product Sans Light";
  src: url('./assets/fonts/ProductSans-Light.ttf');
}

@font-face {
  font-family: "Product Sans Regular";
  src: url('./assets/fonts/ProductSans-Regular.ttf');
}

@font-face {
  font-family: "Product Sans Medium";
  src: url('./assets/fonts/ProductSans-Medium.ttf');
}

@font-face {
  font-family: "Product Sans Bold";
  src: url('./assets/fonts/ProductSans-Bold.ttf');
}

@font-face {
  font-family: "Product Sans Black";
  src: url('./assets/fonts/ProductSans-Black.ttf');
}

@font-face {
  font-family: "Roboto Light";
  src: url('./assets/fonts/Roboto-Light.ttf');
}

@font-face {
  font-family: "Roboto Thin";
  src: url('./assets/fonts/Roboto-Thin.ttf');
}

@font-face {
  font-family: "Roboto Regular";
  src: url('./assets/fonts/Roboto-Regular.ttf');
}

@font-face {
  font-family: "Roboto Medium";
  src: url('./assets/fonts/Roboto-Medium.ttf');
}

@font-face {
  font-family: "Roboto Bold";
  src: url('./assets/fonts/Roboto-Bold.ttf');
}

@font-face {
  font-family: "Roboto Black";
  src: url('./assets/fonts/Roboto-Black.ttf');
}

@font-face {
  font-family: "SF Light";
  src: url('./assets/fonts/SFUIText-Light.otf');
}

@font-face {
  font-family: "SF Regular";
  src: url('./assets/fonts/SFUIText-Regular.otf');
}

@font-face {
  font-family: "SF Medium";
  src: url('./assets/fonts/SFUIText-Medium.otf');
}

@font-face {
  font-family: "SF Semibold";
  src: url('./assets/fonts/SFUIText-Semibold.otf');
}

@font-face {
  font-family: "SF Bold";
  src: url('./assets/fonts/SFUIText-Bold.otf');
}


@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-1.25em);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.details5[open] {
  animation-name: fadeInDown;
  animation-duration: 0.5s;
}


  
